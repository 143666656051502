import React from 'react';

import Button from '@glass/shared/components/Button/Button';

function AuthSecondaryButton(props) {
  return <Button {...props} />;
}

AuthSecondaryButton.defaultProps = {
  color: 'secondary',
  size: 'small',
};

export default AuthSecondaryButton;
