import React from 'react';

import { INFO_SEVERITY } from '@glass/common/modules/constants/severities';
import AlertMui, { AlertProps as AlertMuiProps } from '@glass/web/components/base/Alert';
import AlertMuiTitle from '@glass/web/components/base/AlertTitle';

import useExtendText from '@glass/shared/modules/content/useExtendText';

export type AlertProps = {
  title?: string;
  className?: string;
  action?: React.ReactNode;
  severity?: AlertMuiProps['severity'];
  message?: React.ReactNode;
  onClose?: AlertMuiProps['onClose'];
  variant?: AlertMuiProps['variant'];
  icon?: AlertMuiProps['icon'];
  classes?: AlertMuiProps['classes'];
};

function Alert(
  {
    className,
    variant,
    severity = INFO_SEVERITY,
    action,
    title: titleProp,
    message: messageProp,
    onClose,
    icon,
    classes,
  }: AlertProps,
  ref:
    | ((instance: HTMLDivElement | null) => void)
    | React.RefObject<HTMLDivElement>
    | null
    | undefined,
) {
  const title = useExtendText(titleProp);
  const message = useExtendText(messageProp);

  if (!title && !message) {
    return null;
  }

  return (
    <AlertMui
      ref={ref}
      action={action}
      className={className}
      classes={classes}
      icon={icon}
      severity={severity}
      sx={{ alignItems: 'center' }} // todo: move this to a className
      variant={variant}
      onClose={onClose}
    >
      <>
        {/* @ts-expect-error gutterBottom is a Typography prop not defined in the MUI AlertTitleProps, but is valid */}
        {title ? <AlertMuiTitle gutterBottom={false}>{title}</AlertMuiTitle> : null}
        {message}
      </>
    </AlertMui>
  );
}

export default React.memo(React.forwardRef(Alert));
