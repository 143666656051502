// todo: remove lodash on client
import startCase from 'lodash/startCase';
import { useMemo } from 'react';

import { PERIOD_DELIMITER } from '@glass/common/modules/strings/constants';

export const generateLabel = (name: string = '') =>
  (name && startCase(name.split(PERIOD_DELIMITER)?.pop()?.replace(/\d/, ''))) || '';

const usePrettyReduxFormLabel = ({ label: labelProp, name }: { label: string; name: string }) =>
  useMemo(() => {
    return labelProp || generateLabel(name);
  }, [labelProp, name]);

export default usePrettyReduxFormLabel;
