import React from 'react';
import { FormName } from 'redux-form';

export type WithFormNameProps = {
  form: string;
  sectionPrefix?: string;
};

type WithFormNameComponentProps = WithFormNameProps & {
  [key: string]: any;
};

const withFormName = (WrappedComponent: React.ElementType) =>
  function WithFormName(props: WithFormNameComponentProps) {
    return (
      <FormName>
        {({ form, sectionPrefix }) => {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <WrappedComponent {...props} form={form} sectionPrefix={sectionPrefix} />;
        }}
      </FormName>
    );
  };

export default withFormName;
