import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';

import { FORM_ID } from '@glass/web/modules/content/pages/contentIds';

import withTrackingContext from '@glass/shared/modules/tracking/withTrackingContext';

function FormContainer(props) {
  const { className, children, handleSubmit } = props;
  return (
    <form className={className} onSubmit={handleSubmit}>
      {typeof children === 'function' ? children(props) : children}
    </form>
  );
}

FormContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  className: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
};

FormContainer.displayName = 'FormContainer';

export default withTrackingContext(reduxForm()(FormContainer), FORM_ID, {
  contextPropKey: FORM_ID,
});
