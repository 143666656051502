import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getFormError } from 'redux-form';

import withFormName, { WithFormNameProps } from '@glass/web/components/Form/withFormName';
import Box from '@glass/web/components/base/Box';
import Collapse from '@glass/web/components/base/Collapse';
import Typography, { TypographyProps } from '@glass/web/components/base/Typography';

type FormErrorProps = {
  className?: string;
  align: TypographyProps['align'];
} & WithFormNameProps;

function FormError({ className, form, align }: FormErrorProps) {
  const selectError = useMemo(() => getFormError(form), [form]);
  const error = useSelector(selectError) as string;
  return (
    <Collapse in={!!error}>
      <Box py={0.5}>
        <Typography
          align={align}
          className={className}
          color="error"
          component="div"
          variant="caption"
        >
          {error}
        </Typography>
      </Box>
    </Collapse>
  );
}

export default withFormName(FormError);
