import Router from 'next/router';
import PropTypes from 'prop-types';
import React, { Suspense, useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import createOnHandler from '@glass/common/modules/utils/createOnHandler';
import SpinnerWithContainer from '@glass/web/components/Loading/SpinnerWithContainer';
import resetGlobalDialogAction from '@glass/web/modules/globalDialog/resetGlobalDialogAction';
import makeStyles from '@glass/web/modules/theme/makeStyles';

import AuthAlert from '@glass/shared/components/Auth/AuthAlert';
// eslint-disable-next-line import/no-cycle
import EmailView from '@glass/shared/components/Auth/EmailView';
import MessageView from '@glass/shared/components/Auth/MessageView';
import PasswordView from '@glass/shared/components/Auth/PasswordView';
import AUTH_BODY_MIN_HEIGHT from '@glass/shared/components/Auth/authBodyMinHeight';
import SlideContainer from '@glass/shared/components/Transition/SlideContainer';
import { selectAuthProps } from '@glass/shared/modules/authForm';
import setAuthPropsAction from '@glass/shared/modules/authForm/actions/setAuthPropsAction';
import {
  CHILDREN_AUTH_VIEW,
  EMAIL_AUTH_VIEW,
  MESSAGE_AUTH_VIEW,
  PASSWORD_AUTH_VIEW,
} from '@glass/shared/modules/authForm/authViews';
import selectUserContactEmail from '@glass/shared/modules/userConfig/selectors/selectUserContactEmail';

const useStyles = makeStyles()({
  root: {
    width: '100%',
    overflow: 'hidden',
    minHeight: AUTH_BODY_MIN_HEIGHT,
  },
});

const useKey = ({ view, actionType }) =>
  useMemo(() => [view, actionType].join(), [view, actionType]);

// todo: cleanup next params / next combine
function AuthForm({
  initialView,
  initialActionType,
  initialOnAuth,
  initialRequirePassword,
  initialNext,
  initialMessage,
  initialAvailableActions,
}) {
  const authProps = useSelector(selectAuthProps, shallowEqual);
  const possibleEmail = useSelector(selectUserContactEmail);
  const dispatch = useDispatch();

  const {
    email,
    view: authView,
    actionType: authActionType,
    message: authMessage,
    title,
    children,
    toggleAuthWithinComponent,
    onAuth: authOnAuth,
    next: authNext,
    onExit,
    inDialog,
    requirePassword: authRequirePassword,
    availableActions: authAvailableActions,
  } = authProps;

  const actionType = authActionType || initialActionType;
  const view = authView || initialView;
  const onAuth = authOnAuth || initialOnAuth;
  const next = authNext || initialNext;
  const requirePassword = authRequirePassword || initialRequirePassword;
  const message = authMessage || initialMessage;
  const availableActions = authAvailableActions || initialAvailableActions;

  const key = useKey({ view, actionType });
  const { classes } = useStyles();

  useEffect(() => {
    return () => dispatch(setAuthPropsAction());
  }, [dispatch]);

  const initialValues = useMemo(() => ({ email: email || possibleEmail }), [email, possibleEmail]);

  const handleAuth = useCallback(
    (result) => {
      return Promise.resolve(createOnHandler(onAuth)(result))
        .then(() => {
          if (next) {
            return inDialog ? Router.push(next) : Router.replace(next);
          }
          return undefined;
        })
        .then(() => {
          if (inDialog) {
            dispatch(resetGlobalDialogAction());
          }
          dispatch(setAuthPropsAction());
          return result;
        });
    },
    [dispatch, inDialog, next, onAuth],
  );

  const handleExit = useCallback(
    async (result) => {
      return Promise.resolve(createOnHandler(onExit)(result)).then(() => {
        if (inDialog) {
          dispatch(resetGlobalDialogAction());
        }
        dispatch(setAuthPropsAction());
        return result;
      });
    },
    [onExit, dispatch, inDialog],
  );

  return (
    <div className={classes.root}>
      <SlideContainer transitionKey={key}>
        <AuthAlert actionType={actionType} view={view} />
        {view === EMAIL_AUTH_VIEW ? (
          <EmailView
            actionType={actionType}
            availableActions={availableActions}
            email={email}
            inDialog={inDialog}
            initialValues={initialValues}
            message={message}
            next={next}
            requirePassword={requirePassword}
            title={title}
            toggleAuthWithinComponent={toggleAuthWithinComponent}
            onAuth={handleAuth}
            onExit={handleExit}
          />
        ) : null}
        {view === PASSWORD_AUTH_VIEW ? (
          <PasswordView
            actionType={actionType}
            availableActions={availableActions}
            email={email}
            inDialog={inDialog}
            initialValues={initialValues}
            message={message}
            next={next}
            requirePassword={requirePassword}
            title={title}
            toggleAuthWithinComponent={toggleAuthWithinComponent}
            onAuth={handleAuth}
            onExit={handleExit}
          />
        ) : null}
        {view === MESSAGE_AUTH_VIEW ? (
          <MessageView
            actionType={actionType}
            availableActions={availableActions}
            email={email}
            inDialog={inDialog}
            initialValues={initialValues}
            message={message}
            next={next}
            requirePassword={requirePassword}
            title={title}
            toggleAuthWithinComponent={toggleAuthWithinComponent}
            onAuth={handleAuth}
            onExit={handleExit}
          />
        ) : null}
        {view === CHILDREN_AUTH_VIEW ? (
          <Suspense fallback={<SpinnerWithContainer />}>
            <div>{children}</div>
          </Suspense>
        ) : null}
      </SlideContainer>
    </div>
  );
}

AuthForm.propTypes = {
  initialActionType: PropTypes.string,
  initialAvailableActions: PropTypes.arrayOf(PropTypes.string),
  initialMessage: PropTypes.string,
  initialNext: PropTypes.string,
  initialOnAuth: PropTypes.func,
  initialRequirePassword: PropTypes.bool,
  initialView: PropTypes.string,
};

export default React.memo(AuthForm);
