import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import FormTextField from '@glass/shared/components/Form/FormTextField';
import { SIGNIN_ACTION } from '@glass/shared/modules/auth/authActionTypes';
import password from '@glass/shared/modules/form/fieldHelpers/password';

const PASSWORD = 'password';

function PasswordField({
  actionType,
  inputProps: inputPropsProp,
  validate: validateProp,
  warn: warnProp,
  ...props
}) {
  const validate = useMemo(
    () => (actionType !== SIGNIN_ACTION ? [password, ...validateProp] : validateProp),
    [validateProp, actionType],
  );

  const warn = useMemo(
    () => (actionType !== SIGNIN_ACTION ? [password, ...warnProp] : warnProp),
    [warnProp, actionType],
  );

  const inputProps = useMemo(
    () => ({
      autoComplete: actionType === SIGNIN_ACTION ? `current-${PASSWORD}` : `new-${PASSWORD}`,
      name: PASSWORD,
      ...inputPropsProp,
    }),
    [actionType, inputPropsProp],
  );

  return (
    <FormTextField
      inputProps={inputProps}
      label="Password"
      name={PASSWORD}
      type={PASSWORD}
      validate={validate}
      warn={warn}
      {...props}
    />
  );
}

PasswordField.propTypes = {
  actionType: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  validate: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  warn: PropTypes.array,
};

PasswordField.defaultProps = {
  inputProps: {},
  validate: [],
  warn: [],
};

export default PasswordField;
