import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { NEXT_QUERY } from '@glass/common/modules/pages/queryParams';

import AuthSecondaryButton from '@glass/shared/components/Auth/AuthSecondaryButton';
import AUTH_PAGES from '@glass/shared/components/Auth/authPages';
import Link from '@glass/shared/components/Link/Link';
// eslint-disable-next-line import/no-cycle
import promptAuthAction from '@glass/shared/modules/authForm/actions/promptAuthAction';
import AUTH_TITLES from '@glass/shared/modules/authForm/authTitles';

const isRestrictedAction = (action, availableActions) =>
  availableActions && !availableActions.includes(action);

function AuthActionButton({
  actionType,
  toggleAuthWithinComponent,
  view,
  next,
  availableActions,
  onClick,
  variant,
  size,
  color,
  fullWidth,
  children,
}) {
  const dispatch = useDispatch();
  const { pathname } = useRouter();

  const handleClick = useCallback(
    (evt) => {
      if (typeof onClick === 'function') {
        onClick(evt);
      }
      if (toggleAuthWithinComponent || AUTH_PAGES[actionType] === pathname) {
        evt.preventDefault();
        dispatch(
          promptAuthAction({
            actionType,
            view,
          }),
        );
      }
    },
    [onClick, toggleAuthWithinComponent, actionType, view, pathname, dispatch],
  );

  const isRestricted = useMemo(
    () => isRestrictedAction(actionType, availableActions),
    [actionType, availableActions],
  );

  const href = useMemo(
    () => ({
      pathname: AUTH_PAGES[actionType],
      query: {
        [NEXT_QUERY]: next,
      },
    }),
    [actionType, next],
  );

  if (isRestricted) {
    return null;
  }

  return (
    <AuthSecondaryButton
      disableElevation
      color={color}
      component={Link}
      fullWidth={fullWidth}
      href={href}
      size={size}
      variant={variant}
      onClick={handleClick}
    >
      {children || AUTH_TITLES[actionType]}
    </AuthSecondaryButton>
  );
}

AuthActionButton.propTypes = {
  actionType: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  availableActions: PropTypes.array,
  children: PropTypes.node,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  next: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  toggleAuthWithinComponent: PropTypes.bool,
  variant: PropTypes.string,
  view: PropTypes.string,
};

export default React.memo(AuthActionButton);
