import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

// eslint-disable-next-line import/no-cycle
import AuthActionButton from '@glass/shared/components/Auth/AuthActionButton';
import AuthTextField from '@glass/shared/components/Auth/AuthTextField';
import AuthView from '@glass/shared/components/Auth/AuthView';
import EmailField from '@glass/shared/components/Form/EmailField';
import {
  CREATE_ACCOUNT_ACTION,
  RESET_PASSWORD_ACTION,
  ROCKET_LINK_ACTION,
  SIGNIN_ACTION,
} from '@glass/shared/modules/auth/authActionTypes';
import createEmailLoginAction from '@glass/shared/modules/authForm/actions/createEmailLoginAction';
import createEmailResetPasswordAction from '@glass/shared/modules/authForm/actions/createEmailResetPasswordAction';
import createEmailRocketLinkAction from '@glass/shared/modules/authForm/actions/createEmailRocketLinkAction';
import createEmailSignupAction from '@glass/shared/modules/authForm/actions/createEmailSignupAction';
import AUTH_TITLES from '@glass/shared/modules/authForm/authTitles';

const CREATE_SUBMIT_ACTIONS = {
  [SIGNIN_ACTION]: createEmailLoginAction,
  [CREATE_ACCOUNT_ACTION]: createEmailSignupAction,
  [RESET_PASSWORD_ACTION]: createEmailResetPasswordAction,
  [ROCKET_LINK_ACTION]: createEmailRocketLinkAction,
};

function EmailView(props) {
  const {
    actionType,
    toggleAuthWithinComponent,
    email,
    availableActions,
    message,
    title,
    initialValues,
    inDialog,
    next,
  } = props;

  const toggleAction = actionType === SIGNIN_ACTION ? CREATE_ACCOUNT_ACTION : SIGNIN_ACTION;
  const hideToggle = useMemo(() => [ROCKET_LINK_ACTION].includes(actionType), [actionType]);

  return (
    <AuthView
      actionType={actionType}
      buttonChildren={
        !hideToggle ? (
          <>
            <AuthActionButton
              actionType={toggleAction}
              availableActions={availableActions}
              next={next}
              toggleAuthWithinComponent={toggleAuthWithinComponent}
            />
            {actionType !== RESET_PASSWORD_ACTION ? (
              <AuthActionButton
                actionType={RESET_PASSWORD_ACTION}
                availableActions={availableActions}
                next={next}
                toggleAuthWithinComponent={toggleAuthWithinComponent}
              >
                Forgot Password?
              </AuthActionButton>
            ) : null}
          </>
        ) : null
      }
      inDialog={inDialog}
      initialValues={initialValues}
      message={message}
      requireTerms={actionType === CREATE_ACCOUNT_ACTION}
      title={title || AUTH_TITLES[actionType]}
      onSubmit={CREATE_SUBMIT_ACTIONS[actionType](props, { email })}
    >
      <AuthTextField component={EmailField} />
    </AuthView>
  );
}

EmailView.defaultProps = {
  message: 'Enter your email to get started',
};

EmailView.propTypes = {
  actionType: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  availableActions: PropTypes.array,
  email: PropTypes.string,
  inDialog: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object,
  message: PropTypes.string,
  next: PropTypes.string,
  title: PropTypes.string,
  toggleAuthWithinComponent: PropTypes.bool,
};

export default EmailView;
