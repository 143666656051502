import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import makeStyles from '@glass/web/modules/theme/makeStyles';

import createSlideStyles, {
  transitionEndListener,
} from '@glass/shared/modules/theme/styles/createSlideStyles';

const useStyles = makeStyles()(createSlideStyles);

function SlideContainer({ transitionKey, children }) {
  const { classes } = useStyles();
  const transitionClassNames = useMemo(
    () => ({
      enter: classes.enter,
      enterActive: classes.enterActive,
      exit: classes.exit,
      exitActive: classes.exitActive,
    }),
    [classes.enter, classes.enterActive, classes.exit, classes.exitActive],
  );

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={transitionKey}
        addEndListener={transitionEndListener}
        classNames={transitionClassNames}
      >
        <div>{children}</div>
      </CSSTransition>
    </SwitchTransition>
  );
}

SlideContainer.propTypes = {
  children: PropTypes.node.isRequired,
  transitionKey: PropTypes.string.isRequired,
};

export default SlideContainer;
