const fieldPropsToInputProps = (fieldProps, defaultProps = {}) => {
  const {
    input: { name, value, ...inputProps },
    meta: { active, valid, submitting, touched, error, warning },
    successAdornment,
    InputProps = {},
    disabled,
    helperText,
    ...otherProps
  } = fieldProps;

  const showWarning = (touched || active) && warning;
  const showError = touched && error;

  return {
    ...defaultProps,
    ...otherProps,
    error: showError || undefined,
    helperText: showError || showWarning || helperText,
    disabled: disabled || submitting,
    name,
    value,
    InputProps: {
      ...InputProps,
      endAdornment: successAdornment && valid && value ? successAdornment : InputProps.endAdornment,
    },
    ...inputProps,
  };
};

export default fieldPropsToInputProps;
