import React from 'react';

import usePrettyReduxFormLabel from '@glass/web/modules/controls/usePrettyReduxFormLabel';

import fieldPropsToInputProps from '@glass/shared/modules/form/fieldHelpers/fieldPropsToInputProps';

const toRf = (Component) =>
  function (props) {
    // eslint-disable-next-line react/prop-types
    const { input, label, ...otherProps } = props;
    const finalLabel = usePrettyReduxFormLabel({ ...input, label });
    return (
      <Component
        {...fieldPropsToInputProps(props, { fullWidth: true })}
        label={finalLabel}
        {...otherProps}
      />
    );
  };

export default toRf;
