import { RESET_PASSWORD_EMAIL } from '@glass/common/modules/email/emailIds';
import {
  PROFILE_CHANGE_PASSWORD_PATHNAME,
  RESET_PASSWORD_PATHNAME,
} from '@glass/common/modules/pages/paths';
import { FAIL_QUERY, NEXT_QUERY } from '@glass/common/modules/pages/queryParams';

import { RESET_PASSWORD_ACTION } from '@glass/shared/modules/auth/authActionTypes';
import sendCommunicationAction from '@glass/shared/modules/auth/sendCommunicationAction';
// eslint-disable-next-line import/no-cycle
import promptAuthAction from '@glass/shared/modules/authForm/actions/promptAuthAction';
import { MESSAGE_AUTH_VIEW } from '@glass/shared/modules/authForm/authViews';

const createEmailResetPasswordAction =
  ({ next, actionType }) =>
  ({ email }) =>
  (dispatch) => {
    const nextQuery = next || window?.location?.href;
    return dispatch(
      sendCommunicationAction({
        emailId: RESET_PASSWORD_EMAIL,
        email,
        options: {
          ctaPath: PROFILE_CHANGE_PASSWORD_PATHNAME,
          ctaQuery: {
            [NEXT_QUERY]: nextQuery,
            [FAIL_QUERY]: RESET_PASSWORD_PATHNAME,
          },
        },
      }),
    ).then(() =>
      dispatch(
        promptAuthAction({
          email,
          view: MESSAGE_AUTH_VIEW,
          title: 'Check your email',
          message: `If there is an account for <b>${email}</b>, you'll receive an email to ${
            actionType === RESET_PASSWORD_ACTION ? 'reset' : 'create'
          } your password`,
        }),
      ),
    );
  };

export default createEmailResetPasswordAction;
