import PropTypes from 'prop-types';
import React from 'react';

import Logo, { NATURAL_HEIGHT, NATURAL_WIDTH } from '@glass/web/components/App/Logo';
import commonTheme from '@glass/web/modules/theme/common';
import makeStyles from '@glass/web/modules/theme/makeStyles';

import defaultThemeColor from '@glass/shared/modules/theme/styles/themeColor';

const IMAGE_HEIGHT = 40;
const IMAGE_WIDTH = (NATURAL_WIDTH * IMAGE_HEIGHT) / NATURAL_HEIGHT;

export const AUTH_HEADER_HEIGHT = IMAGE_HEIGHT + 2 * commonTheme.muiTheme.spacing;

const useStyles = makeStyles()((theme) => ({
  header: {
    padding: theme.spacing(1),
    backgroundColor: defaultThemeColor,
  },
  logo: {
    width: IMAGE_WIDTH,
    color: 'white',
    display: 'block',
    margin: '0 auto',
  },
}));

function DialogHeader({ children }) {
  const { classes } = useStyles();
  return (
    <div>
      <div className={classes.header}>
        <Logo className={classes.logo} />
      </div>
      {children}
    </div>
  );
}

DialogHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DialogHeader;
