import React from 'react';

import Typography from '@glass/web/components/base/Typography';
import { CENTER } from '@glass/web/modules/theme/constants';

function AuthMessage(props) {
  return <Typography {...props} />;
}

AuthMessage.defaultProps = {
  align: CENTER,
  variant: 'h6',
};

export default AuthMessage;
