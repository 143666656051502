import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BRAND_TITLE_NBSP } from '@glass/common/modules/environments/brands';
import { selectUser } from '@glass/web/modules/auth';
import { EMAIL_FIELD_V2 } from '@glass/web/modules/resumes/fieldKeys';

// eslint-disable-next-line import/no-cycle
import AuthActionButton from '@glass/shared/components/Auth/AuthActionButton';
import AuthTextField from '@glass/shared/components/Auth/AuthTextField';
import AuthView from '@glass/shared/components/Auth/AuthView';
import EmailChip from '@glass/shared/components/Auth/EmailChip';
import PasswordField from '@glass/shared/components/Form/PasswordField';
import {
  CHANGE_PASSWORD_ACTION,
  CREATE_ACCOUNT_ACTION,
  RESET_PASSWORD_ACTION,
  ROCKET_LINK_ACTION,
  SIGNIN_ACTION,
} from '@glass/shared/modules/auth/authActionTypes';
import signOutUserAction from '@glass/shared/modules/auth/signOutUserAction';
import createEmailChangePasswordAction from '@glass/shared/modules/authForm/actions/createPasswordChangetPasswordAction';
import createPasswordLoginAction from '@glass/shared/modules/authForm/actions/createPasswordLoginAction';
import createPasswordSignupAction from '@glass/shared/modules/authForm/actions/createPasswordSignupAction';
import promptAuthAction from '@glass/shared/modules/authForm/actions/promptAuthAction';
import AUTH_TITLES from '@glass/shared/modules/authForm/authTitles';
import { EMAIL_AUTH_VIEW } from '@glass/shared/modules/authForm/authViews';

const CREATE_SUBMIT_ACTIONS = {
  [SIGNIN_ACTION]: createPasswordLoginAction,
  [CREATE_ACCOUNT_ACTION]: createPasswordSignupAction,
  [CHANGE_PASSWORD_ACTION]: createEmailChangePasswordAction,
};

const PASSWORD_TITLES = {
  [SIGNIN_ACTION]: `Welcome back to ${BRAND_TITLE_NBSP}`,
};

const hiddenInputStyle = { display: 'none' };

function PasswordView(props) {
  const {
    actionType,
    toggleAuthWithinComponent,
    title,
    message,
    email,
    next,
    availableActions,
    inDialog,
  } = props;
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const handleEmailClick = useCallback(() => {
    dispatch(promptAuthAction({ view: EMAIL_AUTH_VIEW }));
    if (user) {
      return dispatch(signOutUserAction({ path: false, snackbar: false }));
    }
    return null;
  }, [user, dispatch]);

  const hideSignIn = useMemo(
    () => [CHANGE_PASSWORD_ACTION, ROCKET_LINK_ACTION].includes(actionType),
    [actionType],
  );

  const hideCreateAccount = useMemo(
    () => hideSignIn || [CREATE_ACCOUNT_ACTION].includes(actionType),
    [actionType, hideSignIn],
  );

  const handleSubmit = useMemo(
    () => CREATE_SUBMIT_ACTIONS[actionType]?.(props),
    [actionType, props],
  );

  return (
    <AuthView
      actionType={actionType}
      buttonChildren={
        <>
          {!hideCreateAccount ? (
            <AuthActionButton
              actionType={CREATE_ACCOUNT_ACTION}
              availableActions={availableActions}
              next={next}
              toggleAuthWithinComponent={toggleAuthWithinComponent}
              view={EMAIL_AUTH_VIEW}
            />
          ) : null}
          {!hideSignIn ? (
            <AuthActionButton
              actionType={RESET_PASSWORD_ACTION}
              availableActions={availableActions}
              next={next}
              toggleAuthWithinComponent={toggleAuthWithinComponent}
              view={EMAIL_AUTH_VIEW}
            >
              Forgot Password?
            </AuthActionButton>
          ) : null}
        </>
      }
      inDialog={inDialog}
      message={message}
      title={title || PASSWORD_TITLES[actionType] || AUTH_TITLES[actionType]}
      titleChildren={
        <div>
          <EmailChip
            label={user ? user.email : email}
            onClick={user ? undefined : handleEmailClick}
          />
          <input
            readOnly
            autoComplete="username email"
            name={EMAIL_FIELD_V2}
            style={hiddenInputStyle}
            type={EMAIL_FIELD_V2}
            value={email}
          />
        </div>
      }
      onSubmit={handleSubmit}
    >
      <AuthTextField actionType={actionType} component={PasswordField} />
    </AuthView>
  );
}

PasswordView.propTypes = {
  actionType: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  availableActions: PropTypes.array,
  email: PropTypes.string,
  inDialog: PropTypes.bool,
  message: PropTypes.string,
  next: PropTypes.string,
  title: PropTypes.string,
  toggleAuthWithinComponent: PropTypes.bool,
};

PasswordView.defaultProps = {
  title: 'Welcome',
  message: 'Enter a password to continue',
};

export default React.memo(PasswordView);
