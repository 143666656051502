import PropTypes from 'prop-types';
import React from 'react';

function AuthTextField({ component: Component, ...otherProps }) {
  return (
    <Component autoFocus fullWidth required color="secondary" variant="outlined" {...otherProps} />
  );
}

AuthTextField.propTypes = {
  component: PropTypes.elementType,
};

export default AuthTextField;
