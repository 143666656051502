import changePasswordMutation from '@glass/shared/modules/auth/queries/changePassword.graphql';

const changePasswordAction =
  (variables) =>
  (dispatch, getState, { client }) =>
    client
      .mutate({
        mutation: changePasswordMutation,
        variables,
      })
      .then(({ data }) => data.changePassword);

export default changePasswordAction;
