import PropTypes from 'prop-types';
import React from 'react';

import SafeHtml from '@glass/web/components/SafeHtml/SafeHtml';
import { CENTER } from '@glass/web/modules/theme/constants';
import { FLEX, SPACE_BETWEEN } from '@glass/web/modules/theme/flexConstants';
import makeStyles from '@glass/web/modules/theme/makeStyles';

import AuthFormContainer from '@glass/shared/components/Auth/AuthFormContainer';
import AuthMessage from '@glass/shared/components/Auth/AuthMessage';
import AuthTerms from '@glass/shared/components/Auth/AuthTerms';
import AuthTitle from '@glass/shared/components/Auth/AuthTitle';
import AUTH_BODY_MIN_HEIGHT from '@glass/shared/components/Auth/authBodyMinHeight';
import useExtendText from '@glass/shared/modules/content/useExtendText';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(2),
    minHeight: AUTH_BODY_MIN_HEIGHT,
    display: FLEX,
    flexDirection: 'column',
  },
  form: {
    display: FLEX,
    flexDirection: 'column',
    justifyContent: SPACE_BETWEEN,
    flexGrow: 1,
  },
  titleContainer: {
    textAlign: CENTER,
    justifyContent: CENTER,
    display: FLEX,
    flexDirection: 'column',
    flexGrow: 1,
  },
  childrenContainer: {
    flexGrow: 1,
    margin: theme.spacing(3, 0),
  },
  termsContainer: {
    marginTop: theme.spacing(2),
  },
}));

const BUTTON_TEXT = 'Next';

function AuthView({
  title,
  message,
  children,
  titleChildren,
  inDialog,
  actionType,
  onSubmit,
  initialValues,
  buttonChildren,
  requireTerms,
}) {
  const { classes } = useStyles();
  const extendedTitle = useExtendText(title);
  const extendedMessage = useExtendText(message);
  return (
    <div className={classes.root}>
      <AuthFormContainer
        actionType={actionType}
        buttonChildren={buttonChildren}
        buttonText={BUTTON_TEXT}
        className={classes.form}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        <div className={classes.titleContainer}>
          {extendedTitle ? (
            <AuthTitle
              component={inDialog ? undefined : 'h1'}
              gutterBottom={!!(extendedMessage || titleChildren)}
            >
              <SafeHtml>{extendedTitle}</SafeHtml>
            </AuthTitle>
          ) : null}
          {extendedMessage ? (
            <AuthMessage gutterBottom={!!titleChildren} variant="body1">
              <SafeHtml>{extendedMessage}</SafeHtml>
            </AuthMessage>
          ) : null}
          {titleChildren}
        </div>
        {children ? (
          <div className={classes.childrenContainer}>
            {children}
            {requireTerms ? (
              <div className={classes.termsContainer}>
                <AuthTerms ctaText={BUTTON_TEXT} />
              </div>
            ) : null}
          </div>
        ) : null}
      </AuthFormContainer>
    </div>
  );
}

AuthView.propTypes = {
  actionType: PropTypes.string,
  buttonChildren: PropTypes.node,
  children: PropTypes.node,
  inDialog: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object,
  message: PropTypes.node,
  onSubmit: PropTypes.func,
  requireTerms: PropTypes.bool,
  title: PropTypes.string,
  titleChildren: PropTypes.node,
};

AuthView.defaultProps = {
  requireTerms: false,
};

export default AuthView;
