export const transitionEndListener = (node, done) => {
  node.addEventListener('transitionend', done, false);
};

const createSlideStyles = (theme) => ({
  enter: {
    transform: 'translateX(200px)',
    opacity: 0,
  },
  enterActive: {
    transform: 'translateX(0px)',
    transition: theme.transitions.create(['transform', 'opacity'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    opacity: 1,
  },
  exit: {
    transform: 'translateX(0px)',
    opacity: 1,
  },
  exitActive: {
    opacity: 0,
    transform: 'translateX(-200px)',
    transition: theme.transitions.create(['transform', 'opacity'], {
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
});

export default createSlideStyles;
