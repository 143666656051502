// eslint-disable-next-line import/no-cycle
import promptAuthAction from '@glass/shared/modules/authForm/actions/promptAuthAction';
import { PASSWORD_AUTH_VIEW } from '@glass/shared/modules/authForm/authViews';

const createEmailLoginAction =
  () =>
  ({ email }) =>
  (dispatch) => {
    dispatch(promptAuthAction({ email, view: PASSWORD_AUTH_VIEW }));
    return Promise.resolve({ email });
  };

export default createEmailLoginAction;
