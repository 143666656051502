import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Field } from 'redux-form';

import makeArray from '@glass/common/modules/utils/makeArray';
import TextField from '@glass/web/components/base/TextField';

import requiredValidator from '@glass/shared/modules/form/fieldHelpers/required';
import toRF from '@glass/shared/modules/form/toRf';

const TextFieldRF = toRF(TextField);

function FormTextField({ name, inputProps, validate: validateProps, variant, required, ...props }) {
  const validate = useMemo(
    () => [...(required ? [requiredValidator] : []), ...makeArray(validateProps)],
    [validateProps, required],
  );
  return (
    <Field
      inputProps={{ name, ...inputProps }}
      name={name}
      required={required}
      validate={validate}
      {...props}
      component={TextFieldRF}
      variant={variant || 'standard'}
    />
  );
}

FormTextField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  validate: PropTypes.array,
  variant: PropTypes.string,
};

FormTextField.defaultProps = {
  inputProps: {},
  validate: [],
};

export default React.memo(FormTextField);
