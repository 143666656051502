import PropTypes from 'prop-types';
import React from 'react';

import { BRAND_TITLE_NBSP } from '@glass/common/modules/environments/brands';
import { LEGAL_PRIVACY_SECTION } from '@glass/common/modules/pages/paths';
import { SPACE_STRING } from '@glass/common/modules/strings/constants';
import SafeHtml from '@glass/web/components/SafeHtml/SafeHtml';
import CaptionTypography from '@glass/web/components/Typography/CaptionTypography';

import LegalLink from '@glass/shared/components/Link/LegalLink';

function AuthTerms({ ctaText }) {
  return (
    <CaptionTypography>
      By clicking &quot;{ctaText}&quot;, you agree to
      {SPACE_STRING}
      <SafeHtml>{BRAND_TITLE_NBSP}</SafeHtml>&apos;s
      {SPACE_STRING}
      <LegalLink target="_blank">terms of service</LegalLink> and acknowledge that you&apos;ve read
      and understood the{SPACE_STRING}
      <LegalLink section={LEGAL_PRIVACY_SECTION} target="_blank">
        privacy policy
      </LegalLink>
      .
    </CaptionTypography>
  );
}

AuthTerms.propTypes = {
  ctaText: PropTypes.string,
};

export default React.memo(AuthTerms);
