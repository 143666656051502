import PropTypes from 'prop-types';
import React from 'react';

import Chip from '@glass/web/components/base/Chip';
import AccountCircle from '@glass/web/components/base/icons/AccountCircle';
import ExpandMoreRounded from '@glass/web/components/base/icons/ExpandMoreRounded';

function EmailChip({ label, onClick, ...otherProps }) {
  if (!label) {
    return null;
  }

  const clickable = !!onClick;

  return (
    <Chip
      clickable={clickable}
      deleteIcon={clickable ? <ExpandMoreRounded /> : null}
      icon={<AccountCircle />}
      label={label}
      size="small"
      variant="outlined"
      onClick={onClick}
      onDelete={clickable ? () => {} : undefined}
      {...otherProps}
    />
  );
}

EmailChip.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default EmailChip;
