import {
  CHANGE_PASSWORD_ACTION,
  CREATE_ACCOUNT_ACTION,
  RESET_PASSWORD_ACTION,
  ROCKET_LINK_ACTION,
  SIGNIN_ACTION,
} from '@glass/shared/modules/auth/authActionTypes';

const AUTH_TITLES = {
  [CREATE_ACCOUNT_ACTION]: 'Create Account',
  [SIGNIN_ACTION]: 'Sign in',
  [RESET_PASSWORD_ACTION]: 'Reset password',
  [ROCKET_LINK_ACTION]: 'Request email link',
  [CHANGE_PASSWORD_ACTION]: 'Change password',
};

export default AUTH_TITLES;
