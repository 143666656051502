import changePasswordAction from '@glass/shared/modules/auth/changePasswordAction';
// eslint-disable-next-line import/no-cycle
import promptAuthAction from '@glass/shared/modules/authForm/actions/promptAuthAction';
import { MESSAGE_AUTH_VIEW } from '@glass/shared/modules/authForm/authViews';

const createPasswordChangePasswordAction =
  () =>
  ({ password }) =>
  (dispatch) =>
    dispatch(changePasswordAction({ password })).then(() =>
      dispatch(
        promptAuthAction({
          view: MESSAGE_AUTH_VIEW,
          title: 'Password Updated',
          message: `Your password has been updated`,
        }),
      ),
    );

export default createPasswordChangePasswordAction;
