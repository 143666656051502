import React from 'react';

import Typography from '@glass/web/components/base/Typography';
import { CENTER } from '@glass/web/modules/theme/constants';

function AuthTitle(props) {
  return <Typography {...props} />;
}

AuthTitle.defaultProps = {
  align: CENTER,
  variant: 'h4',
};

export default AuthTitle;
