import Router from 'next/router';

import { RAPTOR_BRAND } from '@glass/common/modules/environments/brands';
import { HOME_PATHNAME, SIGNIN_PATHNAME } from '@glass/common/modules/pages/paths';
import setAppLoadingAction from '@glass/web/modules/app/setAppLoadingAction';
import { selectIsUser } from '@glass/web/modules/auth';
import queueGlobalSnackbarAction from '@glass/web/modules/globalSnackbar/queueGlobalSnackbarAction';

import clearAuthAction from '@glass/shared/modules/auth/clearAuthAction';
import logoutUserMutation from '@glass/shared/modules/auth/queries/logoutUser.graphql';

const nextPath = process.env.NEXT_PUBLIC_BRAND === RAPTOR_BRAND ? SIGNIN_PATHNAME : HOME_PATHNAME;

const signOutUserAction =
  ({ path = nextPath, snackbar = true } = {}) =>
  (dispatch, getState, { client, tracking }) => {
    if (!selectIsUser(getState())) {
      return null;
    }
    dispatch(setAppLoadingAction());
    const exit = () => {
      if (path) {
        return Router.replace(path);
      }
      return Promise.resolve();
    };
    tracking.logout();
    return client
      .mutate({
        mutation: logoutUserMutation,
      })
      .then(exit)
      .then(() => client.clearStore().catch(tracking.exception))
      .then(() => dispatch(clearAuthAction()))
      .then(() => {
        if (snackbar) {
          dispatch(queueGlobalSnackbarAction({ title: "You've been signed out" }));
        }
        return dispatch(setAppLoadingAction(false));
      })
      .catch((err) => {
        dispatch(setAppLoadingAction(false));
        tracking.exception(err);
      });
  };

export default signOutUserAction;
