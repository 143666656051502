import createOnHandler from '@glass/common/modules/utils/createOnHandler';

import signInUserAction from '@glass/shared/modules/auth/signInUserAction';
import { selectAuthProps } from '@glass/shared/modules/authForm';

const createPasswordLoginAction =
  ({ onAuth }) =>
  ({ password, acceptedTermsAt: acceptedTermsAtForm }) =>
  (dispatch, getState) => {
    const { email, acceptedTermsAt: acceptedTermsAtAuth } = selectAuthProps(getState());
    return dispatch(
      signInUserAction({
        email,
        password,
        acceptedTermsAt: acceptedTermsAtForm || acceptedTermsAtAuth,
      }),
    ).then(createOnHandler(onAuth));
  };

export default createPasswordLoginAction;
