import { ROCKET_AUTH_LINK_EMAIL } from '@glass/common/modules/email/emailIds';
import { PROFILE_PATHNAME } from '@glass/common/modules/pages/paths';
import pathToURL from '@glass/common/modules/url/pathToURL';

import sendCommunicationAction from '@glass/shared/modules/auth/sendCommunicationAction';
// eslint-disable-next-line import/no-cycle
import promptAuthAction from '@glass/shared/modules/authForm/actions/promptAuthAction';
import { MESSAGE_AUTH_VIEW } from '@glass/shared/modules/authForm/authViews';

const createEmailRocketLinkAction =
  ({ next }) =>
  ({ email }) =>
  (dispatch) => {
    let ctaPath = PROFILE_PATHNAME;
    let ctaQuery = {};

    if (next) {
      const {
        url: { pathname },
        query,
      } = pathToURL(next, true);
      ctaPath = pathname;
      ctaQuery = query;
    }
    return dispatch(
      sendCommunicationAction({
        emailId: ROCKET_AUTH_LINK_EMAIL,
        email,
        options: {
          ctaPath,
          ctaQuery,
        },
      }),
    ).then(() =>
      dispatch(
        promptAuthAction({
          email,
          view: MESSAGE_AUTH_VIEW,
          title: 'Check your email',
          message: `If there is an account for <b>${email}</b>, you'll receive an email with a Rocket Link to pickup where you left off`,
        }),
      ),
    );
  };

export default createEmailRocketLinkAction;
