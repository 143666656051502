import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isSubmitting as isReduxFormSubmitting } from 'redux-form';

import filterJoin from '@glass/common/modules/strings/filterJoin';
import FormError from '@glass/web/components/Form/FormError';
import LoadingButton from '@glass/web/components/base/LoadingButton';
import { CENTER } from '@glass/web/modules/theme/constants';
import { FLEX, SPACE_BETWEEN } from '@glass/web/modules/theme/flexConstants';
import makeStyles from '@glass/web/modules/theme/makeStyles';

import FormContainer from '@glass/shared/components/Form/FormContainer';
import handleGraphqlFormErrors from '@glass/shared/modules/errors/handleGraphqlFormErrors';

const useStyles = makeStyles()((theme) => ({
  buttonContainer: {
    width: '100%',
    display: FLEX,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  buttonChildrenContainer: {
    marginTop: theme.spacing(1),
    justifyContent: SPACE_BETWEEN,
    display: FLEX,
    flexDirection: 'column',
    alignItems: CENTER,
  },
}));

function AuthFormContainer({
  className,
  actionType,
  onSubmit,
  initialValues,
  buttonChildren,
  buttonText,
  children,
}) {
  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    (form) => {
      if (typeof onSubmit === 'function') {
        const acceptedTermsAt = new Date();
        return Promise.resolve(
          dispatch(
            onSubmit({
              ...form,
              acceptedTermsAt,
            }),
          ),
        ).catch(handleGraphqlFormErrors);
      }
      return undefined;
    },
    [onSubmit, dispatch],
  );
  const { classes } = useStyles();

  const form = useMemo(() => filterJoin(['AuthDialogBase', actionType], '-'), [actionType]);

  const selectIsSubmitting = useMemo(() => isReduxFormSubmitting(form), [form]);
  const isSubmitting = useSelector(selectIsSubmitting);

  return (
    <FormContainer
      enableReinitialize
      forceUnregisterOnUnmount
      className={className}
      form={form}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {children}
      {onSubmit || buttonChildren ? (
        <div className={classes.buttonContainer}>
          <FormError />
          <div>
            {onSubmit ? (
              <LoadingButton
                disableElevation
                fullWidth
                color="secondary"
                loading={isSubmitting}
                type="submit"
                variant="contained"
              >
                {buttonText}
              </LoadingButton>
            ) : null}
          </div>
          {buttonChildren ? (
            <div className={classes.buttonChildrenContainer}>{buttonChildren}</div>
          ) : null}
        </div>
      ) : null}
    </FormContainer>
  );
}

AuthFormContainer.propTypes = {
  actionType: PropTypes.string,
  buttonChildren: PropTypes.node,
  buttonText: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

AuthFormContainer.defaultProps = {
  buttonText: 'Next',
};

export default AuthFormContainer;
