import { selectUser } from '@glass/web/modules/auth';
import getBrowserLanguage from '@glass/web/modules/locales/getBrowserLanguage';
import getBrowserTimezone from '@glass/web/modules/locales/getBrowserTimezone';

import loginUserMutation from '@glass/shared/modules/auth/queries/loginUser.graphql';
import signInAuthAction from '@glass/shared/modules/auth/signInAuthAction';

// returns an authenticated user
const signInUserAction =
  ({ email, password, acceptedTermsAt }) =>
  async (dispatch, getState, { client }) => {
    const user = selectUser(getState());
    if (user) {
      return user;
    }

    return client
      .mutate({
        mutation: loginUserMutation,
        variables: {
          email,
          password,
          acceptedTermsAt,
          browserLanguage: getBrowserLanguage(),
          browserTimezone: getBrowserTimezone(),
        },
      })
      .then(({ data }) => dispatch(signInAuthAction(data?.loginUser)));
  };

export default signInUserAction;
