import { SubmissionError } from 'redux-form';

import isErrorType from '@glass/common/modules/errors/isErrorType';
import { DEFAULT_USER_ERROR } from '@glass/common/modules/errors/messages';
import { COMMA_SPACE_DELIMITER } from '@glass/common/modules/strings/constants';
import { ERROR_LEVEL, INFO_LEVEL } from '@glass/common/modules/tracking/constants/levels';
import AbortSubmissionError from '@glass/web/modules/error/AbortSubmissionError';

import tracking from '@glass/shared/modules/tracking';

const isFieldError = ({ extensions }) => !!extensions?.invalidArgs?.[0];

export const getGraphqlErrorMessages = (error) => {
  const uiMessages = [];
  const fieldErrors = [];
  let level;

  if (!error?.graphQLErrors?.length) {
    return null;
  }

  if (error?.graphQLErrors?.length) {
    error.graphQLErrors.forEach((graphQLError) => {
      const isUiError = isErrorType(graphQLError);
      const isUiFieldError = isFieldError(graphQLError);

      if (isUiError) {
        uiMessages.push(graphQLError.message);
      } else if (isUiFieldError) {
        fieldErrors.push(graphQLError);
      }

      level = level || (isUiError || isUiFieldError ? INFO_LEVEL : ERROR_LEVEL);
    });
  }

  const fieldMessages = fieldErrors.reduce((prevFieldMessages, graphQLError) => {
    const { invalidArgs = [] } = graphQLError?.extensions || {};
    invalidArgs.forEach((field) => {
      // eslint-disable-next-line no-param-reassign
      prevFieldMessages[field] = graphQLError.message;
    });
    return prevFieldMessages;
  }, {});

  return {
    uiMessages,
    uiMessage: uiMessages.join(COMMA_SPACE_DELIMITER),
    fieldErrors,
    fieldMessages,
    level,
  };
};

const handleGraphqlFormErrors = (error) => {
  // ignore this type of error
  if (error instanceof AbortSubmissionError) {
    return null;
  }

  if (error instanceof SubmissionError) {
    // rethrow if already a submission error
    throw error;
  }

  const { uiMessage, fieldMessages, fieldErrors } = getGraphqlErrorMessages(error) || {};

  if (error?.graphQLErrors?.length) {
    error.graphQLErrors.forEach((graphQLError) => {
      const isUiError = isErrorType(graphQLError);
      const isUiFieldError = isFieldError(graphQLError);

      tracking.exception(new Error(graphQLError.message), {
        level: isUiError || isUiFieldError ? INFO_LEVEL : ERROR_LEVEL,
      });
    });
  } else {
    tracking.exception(error);
  }
  // always submit a ui error
  const submission = {
    _error: uiMessage || (!fieldErrors?.length ? DEFAULT_USER_ERROR : ''),
    ...fieldMessages,
  };

  throw new SubmissionError(submission);
};

export default handleGraphqlFormErrors;
