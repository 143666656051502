import createOnHandler from '@glass/common/modules/utils/createOnHandler';
import { selectUser } from '@glass/web/modules/auth';

import changePasswordAction from '@glass/shared/modules/auth/changePasswordAction';
import createUserAction from '@glass/shared/modules/auth/createUserAction';
// eslint-disable-next-line import/no-cycle
import handleCreateUserErrorAction from '@glass/shared/modules/authForm/actions/handleCreateUserErrorAction';

const createPasswordSignupAction =
  (options) =>
  ({ password, acceptedTermsAt: acceptedTermsAtForm }) =>
  (dispatch, getState) => {
    const { email, onAuth, profile, acceptedTermsAt: acceptedTermsAtOptions } = options;
    const user = selectUser(getState());
    if (user) {
      return dispatch(changePasswordAction({ password })).then(() => createOnHandler(onAuth)(user));
    }
    if (email && password) {
      return dispatch(
        createUserAction({
          email,
          password,
          profile,
          acceptedTermsAt: acceptedTermsAtOptions || acceptedTermsAtForm,
        }),
      )
        .then(createOnHandler(onAuth))
        .catch((error) => dispatch(handleCreateUserErrorAction(error, options, { email })));
    }
    throw new Error('invalid at createPasswordSignupAction');
  };

export default createPasswordSignupAction;
