import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PROFILE_PATHNAME } from '@glass/common/modules/pages/paths';
import Collapse from '@glass/web/components/base/Collapse';
import { selectUser } from '@glass/web/modules/auth';

import Alert from '@glass/shared/components/Alert/Alert';
import Button from '@glass/shared/components/Button/Button';
import Link from '@glass/shared/components/Link/Link';
import {
  CREATE_ACCOUNT_ACTION,
  RESET_PASSWORD_ACTION,
  SIGNIN_ACTION,
} from '@glass/shared/modules/auth/authActionTypes';
import { EMAIL_AUTH_VIEW } from '@glass/shared/modules/authForm/authViews';

const useAuthAlert = ({ actionType, view }) => {
  const user = useSelector(selectUser);

  return useMemo(() => {
    let message = null;
    let action = null;

    if (
      user &&
      view === EMAIL_AUTH_VIEW &&
      [RESET_PASSWORD_ACTION, CREATE_ACCOUNT_ACTION, SIGNIN_ACTION].includes(actionType)
    ) {
      message = "You're already signed in";
      action = (
        <Button color="primary" component={Link} href={PROFILE_PATHNAME} size="small">
          Account
        </Button>
      );
    }

    return {
      message,
      action,
    };
  }, [actionType, view, user]);
};

function AuthAlert({ actionType, view }) {
  const { message: alertMessage, action, severity } = useAuthAlert({ actionType, view });

  return (
    <Collapse in={!!alertMessage}>
      <div>
        <Alert action={action} message={alertMessage} severity={severity} />
      </div>
    </Collapse>
  );
}

AuthAlert.propTypes = {
  actionType: PropTypes.string,
  view: PropTypes.string,
};

export default React.memo(AuthAlert);
