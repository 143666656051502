import {
  CREATE_ACCOUNT_PATHNAME,
  RESET_PASSWORD_PATHNAME,
  SIGNIN_PATHNAME,
} from '@glass/common/modules/pages/paths';

import {
  CREATE_ACCOUNT_ACTION,
  RESET_PASSWORD_ACTION,
  SIGNIN_ACTION,
} from '@glass/shared/modules/auth/authActionTypes';

const AUTH_PAGES = {
  [SIGNIN_ACTION]: SIGNIN_PATHNAME,
  [CREATE_ACCOUNT_ACTION]: CREATE_ACCOUNT_PATHNAME,
  [RESET_PASSWORD_ACTION]: RESET_PASSWORD_PATHNAME,
};

export default AUTH_PAGES;
