import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { EMAIL_FIELD_V2 } from '@glass/web/modules/resumes/fieldKeys';

import FormTextField from '@glass/shared/components/Form/FormTextField';
import email from '@glass/shared/modules/form/fieldHelpers/email';

function EmailField({ inputProps, validate, ...props }) {
  const validateProp = useMemo(() => [email, ...validate], [validate]);
  const inputPropsProp = useMemo(
    () => ({
      name: EMAIL_FIELD_V2,
      autoComplete: `${EMAIL_FIELD_V2} username`,
      inputMode: EMAIL_FIELD_V2,
      ...inputProps,
    }),
    [inputProps],
  );

  return (
    <FormTextField
      name={EMAIL_FIELD_V2}
      type={EMAIL_FIELD_V2}
      validate={validateProp}
      {...props}
      inputProps={inputPropsProp}
    />
  );
}

EmailField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  required: PropTypes.bool,
  // eslint-disable-next-line
  validate: PropTypes.array,
};

EmailField.defaultProps = {
  validate: [],
  inputProps: {},
  required: true,
};

export default React.memo(EmailField);
