import Router from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';

import { PROFILE_PATHNAME } from '@glass/common/modules/pages/paths';
import createOnHandler from '@glass/common/modules/utils/createOnHandler';

// eslint-disable-next-line import/no-cycle
import AuthActionButton from '@glass/shared/components/Auth/AuthActionButton';
import AuthView from '@glass/shared/components/Auth/AuthView';
import Button from '@glass/shared/components/Button/Button';
import {
  CHANGE_PASSWORD_ACTION,
  RESET_PASSWORD_ACTION,
  ROCKET_LINK_ACTION,
  SIGNIN_ACTION,
} from '@glass/shared/modules/auth/authActionTypes';

function CloseButton({ onClick, children, color, fullWidth, size, variant }) {
  return (
    <Button color={color} fullWidth={fullWidth} size={size} variant={variant} onClick={onClick}>
      {children}
    </Button>
  );
}

CloseButton.defaultProps = {
  children: 'Close',
  fullWidth: true,
  color: 'secondary',
  variant: 'contained',
  size: 'large',
};

CloseButton.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  variant: PropTypes.string,
};

const resetPasswordProps = ({
  onExit,
  toggleAuthWithinComponent,
  inDialog,
  next,
  availableActions,
}) => {
  return {
    buttonChildren: inDialog ? (
      <CloseButton onClick={onExit} />
    ) : (
      <AuthActionButton
        fullWidth
        actionType={SIGNIN_ACTION}
        availableActions={availableActions}
        color="secondary"
        next={next}
        size="large"
        toggleAuthWithinComponent={toggleAuthWithinComponent}
        variant="contained"
        onClick={onExit}
      />
    ),
  };
};

const changePasswordProps = ({ onExit, inDialog, next }) => {
  const handleNext = () => Router.push(next || PROFILE_PATHNAME).then(createOnHandler(onExit));
  return {
    buttonChildren: inDialog ? (
      <CloseButton onClick={onExit} />
    ) : (
      <CloseButton onClick={handleNext}>Next</CloseButton>
    ),
  };
};

const MESSAGE_PROPS = {
  [ROCKET_LINK_ACTION]: resetPasswordProps,
  [RESET_PASSWORD_ACTION]: resetPasswordProps,
  [CHANGE_PASSWORD_ACTION]: changePasswordProps,
};

function MessageView(props) {
  const {
    actionType,
    inDialog,
    onExit,
    onAuth,
    next,
    requirePassword,
    message,
    availableActions,
    toggleAuthWithinComponent,
    title,
    email,
    initialValues,
  } = props;

  const messageProps =
    typeof MESSAGE_PROPS[actionType] === 'function' ? MESSAGE_PROPS[actionType](props) : {};

  return (
    <AuthView
      actionType={actionType}
      availableActions={availableActions}
      buttonChildren={
        messageProps.buttonChildren || (inDialog ? <CloseButton onClick={onExit} /> : null)
      }
      email={email}
      inDialog={inDialog}
      initialValues={initialValues}
      message={message}
      next={next}
      requirePassword={requirePassword}
      title={title}
      toggleAuthWithinComponent={toggleAuthWithinComponent}
      onAuth={onAuth}
    />
  );
}

MessageView.propTypes = {
  actionType: PropTypes.string,
  availableActions: PropTypes.arrayOf(PropTypes.string),
  email: PropTypes.string,
  inDialog: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object,
  message: PropTypes.string,
  next: PropTypes.string,
  onAuth: PropTypes.func,
  onExit: PropTypes.func,
  requirePassword: PropTypes.bool,
  title: PropTypes.string,
  toggleAuthWithinComponent: PropTypes.bool,
};

export default MessageView;
